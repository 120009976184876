const theme = {
    colorPrimary: '#312F2F',
    colorPrimaryDark: '#222020',
    colorWhite: '#FFFFFF',
    screenWidthM: '1050px',
    screenWidthS: '600px',
    colorLight: '#777777',
    fontFamily: 'IBM Plex Sans',
    fontSizeXl: '1.5rem',
    fontSizeL: '.75rem',
    timeS: '.125s',
    timeM: '.25s',
    timeL: '.5s',
    $colorProvider0: 'rgba(239, 138,98,1)',
    $colorProvider0Alpha: 'rgba(239, 138,98,.3)',
    $colorProvider1: 'rgba(255,255,255,1)',
    $colorProvider1Alpha: 'rgba(255,255,255,.3)',
    colorProvider2: '#67a9cf',
    borderRadius: '6px'
}

export default theme;