import React from 'react';
import { connect } from "react-redux";
import { toggleOverlayInfo } from "../../../store/actions";
import styled from 'styled-components';

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;

    a {
        margin-right: 10px;
        color: white;
        font-family: 'IBM Plex Sans';
        font-weight: 500;
        font-size: 14px;
        opacity: .5;

        &:hover {
            opacity: 1;
        }

    }
`;

function mapStateToProps(state) {
    return {
        districtsMetadata: state.districtsMetadata,
    };
}

class Legal extends React.Component {
    constructor(props) {
        super(props);
    }

    toggleOverlay = () => {
        this.props.dispatch(toggleOverlayInfo(true))
    }

    render() {
        return (
            <StyledWrapper>
                <a target="_blank" href="https://www.technologiestiftung-berlin.de/de/datenschutz/">Datenschutz</a>
                <a target="_blank" href="https://www.technologiestiftung-berlin.de/de/impressum/">Impressum</a>
            </StyledWrapper>
        )
    }
}

export default connect(mapStateToProps)(Legal);