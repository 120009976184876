const stories = [
    {
        storyId: 1,
        title: 'Leihradfreie Zonen',
        descriptionShort: 'Das ist die Kurzbeschreibung',
        description: 'Das Flugfeld des Tempelhofes Flugfhafens ist eins der größten innerstädtischen freistehenden Flächen auf der Welt. Das Feld wird von den Berliner*innen ausgiebig für unterschiedlichste Freizeitaktivitäten verwendet. Besonders Radfahrer nutzen die Landebahnen für Spazierfahrten. Das Abstellen von Leihrädern ist in der Regel untersagt. Hin und wieder verirrt sich allerdings das ein oder andere Rad auf dem Feld.',
        camera: {
            bearing: -20,
            lat: 52.47217633115334,
            lng: 13.409666905999991,
            zoom: 15.2254480797017,
            pitch: 45,
            label: 'Zum Standort'
        }
    },
    {   
        storyId: 0,
        title: 'Fahrradstraßen',
        descriptionShort: 'Das ist die Kurzbeschreibung',
        description: 'In Berlin gibt es einige Fahrradstraßen. Ein Großteil der Leihräder fährt allerdings auf entlang der Torstraße. Möglicherweise ist die Parallelstraße den meisten Leihradfahrern nicht bekannt.',
        camera: {
            lat: 52.52662696311112,
            lng: 13.401849568575383,
            zoom: 15.022597285291257,
            pitch: 45,
            bearing: 0,
            label: 'Zum Standort'
        }
    },
]

export default stories;